import './App.css';
import * as React from 'react';
import { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


function App() {


  const [billContents, setBillContents] = useState([]);
  const [price, setPrice] = useState(0.00)
  const [name, setName] = useState('')
  const [tip, setTip] = useState(0.00)
  const [tax, setTax] = useState(0.00)
  const [grandTotal, setGrandTotal] = useState(0.00)
  const [error, setError] = useState('');


  useEffect(() => {
    calculateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billContents]);


  const addPerson = () => {
    if (name.trim() === '') {
      setError('Name cannot be empty');
    } else {
      setError('');
      setBillContents([
        ...billContents,
        {
          'name': name,
          'price': formatToDecimal(price),
        }
      ]);
      setName('');
      setPrice(0.00);
    }
  }
  const formatToDecimal = (num) => {
    return parseFloat(num);
  }
  const removeItemByIndex = (index) => {
    const updatedBillContents = [...billContents];
    updatedBillContents.splice(index, 1);
    setBillContents(updatedBillContents);
  };

  const calculateTotal = () => {

    let total = 0.00;
    let grandTotal = 0.00;
    billContents.map((item) => {
      return total += item.price;
    })

    let newBillContents = billContents.map((item) => {
      let tipTotal = ((item.price / total) * tip);
      let taxTotal = ((item.price / total) * tax);
      let totalAfterTipAndTax = item.price + tipTotal + taxTotal;
      grandTotal += totalAfterTipAndTax;

      return { ...item, 'tip': tipTotal.toFixed(2), 'tax': taxTotal.toFixed(2), 'total': totalAfterTipAndTax.toFixed(2) };
    });

    setBillContents(newBillContents);
    setGrandTotal(grandTotal);

  }



  return (
    <>
      <Typography sx={{ color: 'white', textAlign: 'center', paddingTop: '3rem', paddingBottom: '1rem', marginBottom: '3rem', fontSize: '2.5rem', backgroundColor: '#118C4F' }}>
        Bill Splitter
      </Typography>
      <div className="App">

        <Grid container spacing={3}>
          <Grid item textAlign={'center'} xs={12}>
            <TextField value={name} onChange={(e) => setName(e.target.value)} required label="Name" variant="outlined" error={error !== ''} helperText={error} />
          </Grid>
          <Grid item textAlign={'center'} xs={12}>
            <TextField type="number" onFocus={(e) => e.target.select()} value={price} required onChange={(e) => setPrice(e.target.value)} label="Price" variant="outlined" />
          </Grid>
          <Grid item textAlign={'center'} xs={12}>
            <Button onClick={addPerson} variant="contained" sx={{ backgroundColor: '#118C4F', ':hover': { backgroundColor: "green" } }}>
              Add Person
            </Button>
          </Grid>

          <Grid item textAlign={'center'} xs={12}>

          </Grid>
          <Grid item textAlign={'center'} xs={12}>
            <TextField type='number' onFocus={(e) => e.target.select()} value={tip} required onChange={(e) => setTip(e.target.value)} label="Tip" variant="outlined" />
          </Grid>
          <Grid item textAlign={'center'} xs={12}>
            <TextField type='number' onFocus={(e) => e.target.select()} value={tax} required onChange={(e) => setTax(e.target.value)} label="Tax" variant="outlined" />
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <TableContainer component={Paper} sx={{ width: 'max-content', }}>
              <Table sx={{ tableLayout: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Name</TableCell>
                    <TableCell align="left">Price</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'block' } }} align="left">Tip</TableCell>
                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align="left">Tax</TableCell>
                    <TableCell align="left">Total </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billContents.map((row, index) => (
                    <>
                      < TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align="left">{row.tip}</TableCell>
                        <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align="left">{row.tax}</TableCell>
                        <TableCell align="left">{row.total}</TableCell>
                        <TableCell align="left">
                          <Button size="small" onClick={() => removeItemByIndex(index)} variant="contained" sx={{ backgroundColor: '#118C4F', ':hover': { backgroundColor: "green" } }}>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>

                    </>
                  ))}
                </TableBody>
              </Table>

            </TableContainer>
          </Grid>
        </Grid>
        <Typography color={'black'} sx={{ paddingTop: '2rem', fontSize: '1.5rem', fontWeight: 'bold' }}>
          Grand Total - ${grandTotal.toFixed(2)}
        </Typography>
      </div >
    </>
  );
}

export default App;
